import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

export const CofuAppIcon: React.FC = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Cofu-App-Icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <div style={{ width: "90px", height: "90px" }}>
      <Img fluid={query.file.childImageSharp.fluid} alt="App icon" />
    </div>
  )
}
