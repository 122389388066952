import { Link } from "gatsby"
import React from "react"
import styles from "./footer.module.css"

const NavLink: React.FC<{
  url?: string
  externalUrl?: string
}> = ({ url, externalUrl, children }) => {
  return (
    <li className={styles.navLink}>
      {url ? (
        <Link to={url}>{children}</Link>
      ) : (
        <a href={externalUrl}>{children}</a>
      )}
    </li>
  )
}

export const Footer: React.FC<{ dark?: boolean }> = ({ dark }) => {
  return (
    <div
      className={`${styles.footerContainer} ${dark == true ? styles.dark : ""}`}
    >
      <ul className={styles.navLinks}>
        <NavLink url="/">Cofu</NavLink>
        <NavLink url="/help">Help</NavLink>
        <NavLink url="/terms-and-privacy">Terms&nbsp;&&nbsp;Privacy</NavLink>
        <NavLink externalUrl="mailto:gabriel.cofu.app@gmail.com">
          Contact
        </NavLink>
      </ul>

      <span className={styles.flexSpacer}></span>

      <span className={styles.copyright}>
        Ⓒ {new Date().getFullYear()} Gabriel Terwesten
      </span>
    </div>
  )
}
